import React from 'react'
import './Home.css'
import BannerClip from '../../videos/banner.mp4'

const Home = () => {
  return (
    <div className='Home'>
      <header className="app-header">
        <video className="banner-video" autoPlay muted loop>
          <source src={BannerClip} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="fade">
          <blockquote>
            "One Day, You Will Leave This World Behind So Live A Life You Will Remember."
            <p>&mdash;<em> Avicii</em>, The Nights</p>
          </blockquote>
        </div>
      </header>
      <div className="home-introduction">
        
      </div>
    </div>
  )
}

export default Home